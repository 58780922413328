<template>
  <div class="page-wrapper white fixed f14">
    <div class="page-info">
      <div class="page-data-wrapper">
        <el-row class="page-top-line">
          <el-col class="item" :sm="12" :md="8">
            <div class="inner">
              <div class="label">状态</div>
              <div class="content">{{ info.approval_status_text }}</div>
            </div>
          </el-col>
          <el-col class="item" :sm="12" :md="8">
            <div class="inner">
              <div class="label">审批步骤</div>
              <div class="content">{{ info.step_status_text }}</div>
            </div>
          </el-col>
          <el-col class="item" :sm="12" :md="8">
            <div class="inner">
              <div class="label">学生姓名</div>
              <div class="content">{{ info.student_name }}</div>
            </div>
          </el-col>
          <el-col class="item" :sm="12" :md="8">
            <div class="inner">
              <div class="label">行政班</div>
              <div class="content">{{ info.class_name }}</div>
            </div>
          </el-col>
          <el-col class="item" :sm="12" :md="8">
            <div class="inner">
              <div class="label">学校</div>
              <div class="content">{{ info.school_name }}</div>
            </div>
          </el-col>
          <el-col class="item" :sm="12" :md="8">
            <div class="inner">
              <div class="label">年级</div>
              <div class="content">{{ info.grade_name }}</div>
            </div>
          </el-col>
          <el-col class="item" :sm="12" :md="8">
            <div class="inner">
              <div class="label">目标任务名称</div>
              <div class="content">{{ info.target_name }}</div>
            </div>
          </el-col>
          <el-col class="item" :sm="12" :md="8">
            <div class="inner">
              <div class="label">上次考试名称</div>
              <div class="content">{{ info.exam_name }}</div>
            </div>
          </el-col>
        </el-row>
      </div>
      <div>
        <div class="page-table-title">考试目标</div>
        <el-table :data="subject" style="width: 100%" size="small" v-loading="loading" :stripe="false">
          <el-table-column prop="subject_name" label="科目"> </el-table-column>
          <el-table-column prop="class_name" label="教学班"> </el-table-column>
          <el-table-column prop="clerk_name" label="教学班老师"> </el-table-column>
          <el-table-column prop="exam_score" label="上次成绩"> </el-table-column>
          <el-table-column prop="score" label="本次期末目标"> </el-table-column>
        </el-table>
      </div>
      <el-dialog title="审批记录" :visible.sync="dialogVisible">
        <el-table :data="list" style="width: 100%" size="small" v-loading="loading" :stripe="false">
          <el-table-column prop="status_text" label="状态"> </el-table-column>
          <el-table-column prop="creator" label="审批人"> </el-table-column>
          <el-table-column prop="created_at" label="审批时间"> </el-table-column>
        </el-table>
      </el-dialog>
    </div>
    <div class="page-tool">
      <el-button type="primary" @click="$router.back()">返回</el-button>
      <el-button @click="dialogVisible = true">审批记录</el-button>
    </div>
  </div>
</template>
<script>
export default {
	_config:{"route":{"path":"detail","meta":{"title":"查看"}}},
  name: 'ExamineVerifyDetails',
  data() {
    return {
      info: {},
      subject: [],
      list: [],
      loading: false,
      dialogVisible: false
    }
  },
  created() {
    this.getDetail()
    this.getRecod()
  },
  methods: {
    getDetail() {
      const id = this.$route.query.id || ''
      const step_status = this.$route.query.step_status | ''
      this.loading = true
      this.$_axios2
        .get(`/api/student/approve/details?id=${id}&step_status=${step_status}`)
        .then(res => {
          const {
            data: {
              data: { subject, info }
            }
          } = res
          this.loading = false
          if (info) {
            this.info = info
            this.subject = subject
          }
        })
        .catch(() => {
          this.loading = false
        })
    },
    getRecod() {
      const id = this.$route.query.id || ''
      this.$_axios2.get(`/api/student/approve/log?id=${id}`).then(res => {
        const {
          data: { data }
        } = res
        this.list = data
      })
    }
  }
}
</script>
<style lang="scss" scoped></style>
